/** global styling **/
/** imports for the web **/
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/** global variables **/
:root {
    /** colors **/
    --pureWhite: #ffffff;
    --mainBlack: #010101;
    --dimBlack: #020b1bc4;
    --dimerBlack: #020b1b9a;
    --mainBlue: #104cba;
    --mainOrange: #FF3834;
    /* --mainOrange:  #d19e3f ; */
    --dimOrange: #ff37340a;
    --mainyellow: #FF7133;
    --dimYellow: #ff6d2f10;
    --loaderOrange: #ff4534d2;
    --dimWhite: #F6F6FF;
    --dimerWhite: #f6f6ff75;
    --dimerBlue: #fafafa;
    --dimBlue: #D0DCEC;
    --mainGreen: #19BC6A;
    --dimGreen: #19bc6a1a;
    --mainRed: #F51854;
    --blurRed: #f51853dc;
    --dimRed: #f5185323;
    --sidebar-admin: #111a2a57;

    /** fonts **/
    --poppins: 'Poppins', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: 0;
}

body {
    width: 100%;
    height: auto;
    background-color: var(--pureWhite);
    font-family: var(--poppins) !important;
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    width: 0.6em !important;
    background: transparent !important;
}

body::-webkit-scrollbar-thumb {
    width: 0.6em !important;
    background: var(--dimBlack) !important;
    border-radius: 0.15em !important;
}

a {
    text-decoration: none !important;
    outline: none;
}

ul {
    list-style: none;
}

input:focus, textarea:focus {
    border: 1px solid #104bbaa4 !important;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    outline: none;
}

button {
    outline: none;
}

.cookies_disabled {
    position: fixed;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    z-index: 999 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cookie_modal_container {
    width: 40%;
    height: 30%;
    border-radius: 0.3em;
    display: flex;
    background: var(--pureWhite);
}

.cookie_modal_container .image {
    width: 28%;
    height: 100%;
}

.cookie_modal_container .image img {
    width: 80%;
    height: auto;
    margin: 10%;
}

.cookie_modal_container .message {
    width: 72%;
    height: 100%;
}

.cookie_modal_container .message h1 {
    color: var(--mainBlack);
    font-size: 1.5em;
    margin-top: 5%;
}

.cookie_modal_container .message p {
    color: var(--mainBlack);
    opacity: 0.6;
    font-size: 0.85em;
    margin-right: 1em;
    margin-top: 2%;
    margin-bottom: 2%;
}

.cookie_modal_container .message button {
    width: 70%;
    outline: none;
    border: none;
    margin-top: 0.2em;
    font-family: var(--poppins);
    border-radius: 0.2em;
    cursor: pointer;
    padding: 0.8em 0 !important;
    transition: .5s ease;
}

.cookie_modal_container .message button:hover {
    background: var(--mainOrange);
    color: var(--pureWhite);
} 

.spinner_loading {
    transition: all .5s ease;
    animation: loading .5s infinite;
}

.image_viewer, .video_viewer {
    position: fixed;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.9);
    left: 0;
    top: 0;
    z-index: 999 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.image_viewer>svg, .video_viewer>svg {
    font-size: 0.88em;
    color: var(--pureWhite);
    display: flex;
    position: fixed;
    top: 5%;
    right: 5%;
    cursor: pointer;
}

.image_viewer>img , .video_viewer>video {
    width: auto;
    max-width: 80%;
    height: auto;
    max-height: 90%;
    border-radius: 0.3em;
}

.video_viewer .videos {
    width: 80%;
    height: auto;
    display: flex;
    margin: 0.2em auto;
    flex-wrap: wrap;
}

.video_viewer .videos>video {
    width: 9%;
    height: auto;
    max-height: 8vh;
    border-radius: 0.25em;
    margin: 0.3em;
    border: 1px solid var(--pureWhite);
    cursor: pointer;
}

.success_message {
    position: fixed;
    height: 20vh;
    bottom: 0;
    left: 0;
    width: 100%;
    background: transparent !important;
    z-index: 999 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.msg_box {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    border-radius: 0.3em;
    padding: 1em 3em;
    background: var(--mainGreen);
    border: 1px solid var(--mainGreen);
    box-shadow: 0.5em 0.3em 0.6em 0.3em var(--dimGreen);
    transition: all .2s ease;
    animation: moving_alert .2s linear;
}

.isFailed {
    background: var(--blurRed) !important;
    color: var(--pureWhite) !important;
    border: 1px solid var(--mainRed) !important;
    box-shadow: 0.5em 0.3em 0.6em 0.3em var(--dimRed) !important;
}

@keyframes moving_alert {
    0% {
        margin-bottom: -5em;
        opacity: 0;
    } 100% {
        margin-top: 0;
        opacity: 1;
    }
}

.success_message .msg_box>p {
    color: var(--pureWhite);
    font-size: 0.9em;
    text-align: center;
}

.success_message .msg_box>svg {
    margin-left: 1em;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }100% {
        transform: rotate(360deg);
    }
}

.loader {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0 !important;
    background: var(--dimWhite) !important;
    z-index: 999 !important;
}

.isLoadingData {
    display: flex;
    height: 5vh;
    width: 10em;
    margin: auto;
    margin-top: 4em;
    align-items: center;
}

.isLoadingData p {
    margin-left: 1em;
    justify-content: center;
    font-size: 0.88em;
}

.--ng_do_not_tample_384_bc_gn, .--ng--ser--8934--2nsdn_jsdncd, .--do_touch_ng_direct_mover {
    display: none !important;
}

.current_viewed {
    transition: .5s ease;
    background: #104bbaa4 !important;
    color: var(--pureWhite) !important;
}

/* responsiveness */

.col-lap-1 {
    width: 8.33% !important;
}

.col-lap-2 {
    width: 16.66% !important;
}

.col-lap-3 {
    width: 25% !important;
}

.col-lap-4 {
    width: 33.33% !important;
}

.col-lap-5 {
    width: 41.66% !important;
}

.col-lap-6 {
    width: 50% !important;
}

.col-lap-7 {
    width: 58.33% !important;
}

.col-lap-8 {
    width: 66.66% !important;
}

.col-lap-9 {
    width: 75% !important;
}

.col-lap-10 {
    width: 83.33% !important;
}

.col-lap-11 {
    width: 91.66% !important;
}

.col-lap-12 {
    width: 100% !important;
}

@media only screen and (max-width: 600px) {

    .video_viewer .videos>video {
        max-height: 12vh;
        width: 15%;
    }

    .msg_box {
        width: 60% !important;
    }

    .success_message .msg_box>p {
        font-size: 0.8em !important;
    }

    .cookie_modal_container {
        flex-direction: row !important;
        height: 50% !important;
        flex-wrap: wrap;
    }

    .cookie_modal_container .image {
        width: 100% !important;
        height: 40% !important;
        display: flex;
        justify-content: center;
    }

    .cookie_modal_container .image img {
        width: 20%;
    }

    .cookie_modal_container .message {
        width: 100% !important;
        height: 60% !important;
        align-items: center !important;
    }

    .cookie_modal_container .message h1 {
        font-size: 1.2em !important;
        margin-top: 0 !important;
        text-align: center;
    }

    .cookie_modal_container .message p {
        margin: 0.9em 10% !important;
        text-align: center;
    }

    .cookie_modal_container .message button {
        width: 90% !important;
        margin: 0.2em 5% !important;
    }

    .col-mob-1 {
        width: 8.33% !important;
    }
    .col-mob-2 {
        width: 16.66% !important;
    }
    .col-mob-3 {
        width: 25% !important;
    }
    .col-mob-4 {
        width: 33.33% !important;
    }
    .col-mob-5 {
        width: 41.66% !important;
    }
    .col-mob-6 {
        width: 50% !important;
    }
    .col-mob-7 {
        width: 58.33% !important;
    }
    .col-mob-8 {
        width: 66.66% !important;
    }
    .col-mob-9 {
        width: 75% !important;
    }
    .col-mob-10 {
        width: 83.33% !important;
    }
    .col-mob-11 {
        width: 91.66% !important;
    }
    .col-mob-12 {
        width: 100% !important;
    }
    .col-mob-auto {
        height: 200vh !important;
    }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .col-tab-1 {
        width: 8.33% !important;
    }
    .col-tab-2 {
        width: 16.66% !important;
    }
    .col-tab-3 {
        width: 25% !important;
    }
    .col-tab-4 {
        width: 33.33% !important;
    }
    .col-tab-5 {
        width: 41.66% !important;
    }
    .col-tab-6 {
        width: 50% !important;
    }
    .col-tab-7 {
        width: 58.33% !important;
    }
    .col-tab-8 {
        width: 66.66% !important;
    }
    .col-tab-9 {
        width: 75% !important;
    }
    .col-tab-10 {
        width: 83.33% !important;
    }
    .col-tab-11 {
        width: 91.66% !important;
    }
    .col-tab-12 {
        width: 100% !important;
    }
    .col-tab-13 {
        width: 48% !important;
        height: 60vh !important;
    }

    .col-tab-14 {
        width: 49% !important;
    }
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
    .col-large-1 {
        width: 8.33% !important;
    }
    .col-large-2 {
        width: 16.66% !important;
    }
    .col-large-3 {
        width: 25% !important;
    }
    .col-large-4 {
        width: 33.33% !important;
    }
    .col-large-5 {
        width: 41.66% !important;
    }
    .col-large-6 {
        width: 50% !important;
    }
    .col-large-7 {
        width: 58.33% !important;
    }
    .col-large-8 {
        width: 66.66% !important;
    }
    .col-large-9 {
        width: 75% !important;
    }
    .col-large-10 {
        width: 83.33% !important;
    }
    .col-large-11 {
        width: 91.66% !important;
    }
    .col-large-12 {
        width: 100% !important;
    }
    .col-large-13 {
        width: 32% !important;
        height: 41vh !important;
    }

    .col-large-14 {
        width: 49% !important;
    }
}